import { useState } from "react";
import { useEffect } from "react";
import { API_BASE_URL, IMAGES_URL } from "../../../constants";
import { deleteActivity, editActivity, getActivity } from "../../../services/activities";
import { useNavigate } from "react-router";






export default function EditActivity(){
    const activityId = window.sessionStorage.getItem('activity');

    const [currentActivity, setCurrentActivity] = useState(null);
    const [newImageFile, setNewImageFile] = useState('');
    const navigate = useNavigate();

    useEffect(()=>{
        if(!Boolean(activityId)){
            navigate('/dashboard/activities');
        }

        getActivity(activityId).then(response=>{
            document.getElementById('title').value = response.data.data.title;
            document.getElementById('text').value = response.data.data.text;
            document.getElementById('currentImage').src = IMAGES_URL+'/'+response.data.data.image.path;
            

        })
    },[])

    function handleEditActivity(){
        var formData = new FormData();
        formData.append('title', document.getElementById('title').value);
        formData.append('text', document.getElementById('text').value);
        formData.append('image', newImageFile[0]);
        formData.append('_method', 'put');

        editActivity(activityId, formData).then(response =>{
            if(response.data.data.image !== undefined) document.getElementById('currentImage').src = IMAGES_URL+'/'+response.data.data.image.path;
            alert('Activity is updated successfully');
        }).catch(err=>{
            console.log(err);
            alert("Couldn't update the activity");
        })
    }

    function handleDeleteActivity(){
        if(window.confirm("Are you sure you want to delete this activity?")){
            deleteActivity(activityId).then(response =>{
                alert(response.data.message);
                navigate('/dashboard/activities');
            }).catch(err=>{
                console.log(err);
                alert("Couldn't delete the activity.");
            })
        }
       
    }

    return (
        <div className="container-fluid" style={{marginTop:0, padding:0, backgroundColor:"#F5F5F5"}}>
            <div className='row text-center' style={{width:"100%", marginRight:"0", marginLeft:"0%", }}>
                <h1 style={{color:"orange", marginTop:"2%"}}>Edit Activity</h1>
                
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="title" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Title: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%", width:"30%"}} type="text" id="title" name="title"></input>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="text" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Text: </label>
                            </div>
                            <div className="col">
                                <textarea style={{marginTop:"1%",  width:"80%",  padding:"1%"}} type="text" id="text" name="text"></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="row">                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="imageFile" style={{width:"100%", marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Current Image: </label>
                            </div>
                            <div className="col">
                                <img id="currentImage" style={{marginTop:"1%", width:"50%"}} src=''/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>New Image: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row text-center" style={{marginTop:"1%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}} onClick={handleEditActivity} type="button" className="btn btn-primary">Edit Activity</button>

                        </div>
                    </div>
                    <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}}  onClick={handleDeleteActivity} type="button" className="btn btn-danger">Delete Activity</button>
                        </div>
                    </div>

                
        </div>
        </div>
        
    )
}
import React from "react";
import "./footBar.css";
import { BsInstagram } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { TiSocialLinkedin } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti"



function FootBar() {
  // return (
    
  //     <footer style={{padding:"0"}}>
  //     <div className="container-fluid" style={{width:"100%",padding:"0", margin:"0"}}>
  //     <div className="row">
  //       <div className="col-md-6"> 
  //         <p className="copyright">Copyright &copy; Tranetech Software Solutions</p>
  //       {/* <div className="mr-auto">
       
  //         <a href="https://www.instagram.com/tranetech_uae/?hl=en">
  //           <BsInstagram className="social" />
  //         </a>
  //         <a href="https://www.facebook.com/tranetechsoftwaresolutions/?ref=page_internal">
  //           {" "}
  //           <FaFacebook className="social" />
  //         </a>
  //         <a href="https://www.linkedin.com/company/tranetech-software-solutions/?originalSubdomain=in">
  //           {" "}
  //           <TiSocialLinkedin className="social" />
  //         </a>
  //         <a href="https://twitter.com/tranetech_uae?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
  //           {" "}
  //           <TiSocialTwitter className="social" />
  //         </a>
  //       </div> */}
  //       </div>
       
  //       <div className="col-md-6">
       
  //       <div >{/*className='footer-contact' */}
  //         <br/>
  //         <br/>
  //         <br/>
               
  //         <p  className="logo-footer">
  //         <small className='logo-footer'>
  //         <p className="logo-desc">Developed & Hosted By </p>
  //         <img className='footer-logo' src="https://tranetech.com/wp-content/uploads/2021/02/tranetechlogo.svg" alt="" />
  //         </small>
  //         </p>
  //         <p  className="mail-footer">
  //         <small className='mail-footer'>
  //         info@tranetech.com<span> +971 244 33 113</span>
  //         </small>
  //         </p>
  //         <p  className="phn-footer">
  //         <small className='phn-footer'>
          
  //         </small>
  //         </p>
  //            {/*<p  className="desc-footer">
  //             <small className='desc-footer'>
  //              Tranetech Software Solutions 
  //             </small>
  //            </p>*/}
  //         </div>



  //       </div>
  //     </div>
  //   </div>
      
  //   </footer>
    
     

      
  // );


  const screen720  = window.visualViewport.width<= 720;
  
  return (
  
  
  
  (screen720)?

      <footer style={{padding:"0"}}>
      <div className="container-fluid" style={{width:"100%",padding:"0", marginTop:"3%"}}>
      <div className="row" style={{width:"100%",padding:"0", margin:"0"}}>
        <div className="col-md-6 my-auto"> 
          
          {/* <p className="copyright" style={{fontSize:"80%"}}>Copyright &copy; Tranetech Software Solutions</p> */}
          <p className="copyright" style={{fontSize:"80%"}}>&copy;Copyright 2022 <span><a href="#" style={{textDecoration: "none", color:"#0047AB"}}>Abu Dhabi Malayalee Samajam. </a></span> All Rights Reserved</p>

        </div>
      
        <div className="col-md-6">
      
     
                
          <div className="row" style={{width:"100%",margin:"0", padding:"0",}}>
            {/* <small className='logo-footer'> */}
              <div className="col" style={{textAlign: "center"}}>
                <p className="logo-desc" style={{margin:"0", fontSize:"100%"}}>Website Designed, Developed & Hosted By </p>

              </div>
            {/* </small> */}

          </div>
          <div className="row" style={{margin:0, width:"100%", justifyContent:"center"}}>
          <a href="https://tranetech.com/"><img className='footer-logo' src="https://tranetech.com/wp-content/uploads/2021/02/tranetechlogo.svg" alt="" /></a>
            
          </div>

          {/* <div className="row" style={{margin:"0"}}>
            <p  className="mail-footer"> info@tranetech.com<span> +971 244 33 113</span> </p>
          </div> */}
          
   



        </div>
      </div>
    </div>
      
    </footer>

    :

    <footer style={{padding:"0"}}>
  <div className="container-fluid" style={{width:"100%",padding:"0", marginTop:"0.5%", marginBottom:"0.5%"}}>
  <div className="row" style={{width:"100%",padding:"0", margin:"0"}}>
    <div className="col-sm-1 text-left my-auto" style={{width: "35%", textAlign:"left", marginLeft:"5%", justifyContent:"center", alignItems:"center"}}> 
        <p className="copyright" style={{fontSize:"80%"}}>&copy;Copyright 2022 <span><a href="#" style={{textDecoration: "none", color:"#0047AB"}}>Abu Dhabi Malayalee Samajam. </a></span> All Rights Reserved</p>
    </div>
   
    <div className="col-sm-1 my-auto" style={{width: "55%", justifyContent:"right", marginRight:"5%", alignContent:"right", padding:"0"}}>
   
      <div style={{float:"right"}}>
        <div className="row">
          <div className="col">
          <div className="row my-auto">

            <div className="col" style={{textAlign:"right", margin:"0", padding:"0"}}>
              <p className="logo-desc" style={{margin:"0", fontSize:"100%"}}>Website Designed,</p>
            </div>
            </div>

            <div className="row">

            <div className="col" style={{textAlign:"right",  margin:"0", padding:"0"}}>
              <p className="logo-desc" style={{margin:"0", fontSize:"100%"}}>Developed &</p>
            </div>

            </div>

            <div className="row">
            <div className="col" style={{textAlign:"right",  margin:"0", padding:"0"}}>
              <p className="logo-desc" style={{margin:"0", fontSize:"100%"}}>Hosted By</p>
            </div>
            </div>

          </div>

          <div className="col">
          <a href="https://tranetech.com/" style={{padding:"0"}}><img className='footer-logo' style={{float:"left"}} src="https://tranetech.com/wp-content/uploads/2021/02/tranetechlogo.svg" alt="" /></a>
          </div>

        </div>
      </div>
     
    </div>

  </div>
</div>
  
</footer>

 

  
);




};



export default FootBar;
import { useState } from "react";
import { useEffect } from "react";
import { API_BASE_URL, IMAGES_URL } from "../../../constants";
import { deleteActivity, editActivity, getActivity } from "../../../services/activities";
import { useNavigate } from "react-router";
import { deleteManager, editManager, getManager } from "../../../services/management";
import { deleteImageSlide, editImageSlide, getImageSlide } from "../../../services/imageslides";






export default function EditImageSlide(){
    const imageSlideId = window.sessionStorage.getItem('imageSlide');

    const [newImageFile, setNewImageFile] = useState('');
    const navigate = useNavigate();

    useEffect(()=>{
        if(!Boolean(imageSlideId)){
            navigate('/dashboard/imageslides');
        }

        getImageSlide(imageSlideId).then(response=>{
            document.getElementById('caption').value = response.data.data.caption;
            document.getElementById('currentImage').src = IMAGES_URL+'/'+response.data.data.image.path;
        })
    },[])

    function handleEditSlide(){
        var formData = new FormData();
        formData.append('caption', document.getElementById('caption').value);

        formData.append('image', newImageFile[0]);
        formData.append('_method', 'put');

        editImageSlide(imageSlideId, formData).then(response =>{
            if(response.data.data.image !== undefined) document.getElementById('currentImage').src = IMAGES_URL+'/'+response.data.data.image.path;
            alert('Image slide is updated successfully');
        }).catch(err=>{
            console.log(err);
            alert("Couldn't update the image slide");
        })
    }

    function handleDeleteSlide(){
        if(window.confirm("Are you sure you want to delete this image slide?")){
            deleteImageSlide(imageSlideId).then(response =>{
                alert(response.data.message);
                navigate('/dashboard/imageslides');
            }).catch(err=>{
                console.log(err);
                alert("Couldn't delete the image slide.");
            })
        }
       
    }

    return (
        <div className="container-fluid" style={{marginTop:0, padding:0, backgroundColor:"#F5F5F5"}}>
            <div className='row text-center' style={{width:"100%", marginRight:"0", marginLeft:"0%", }}>
                <h1 style={{color:"orange", marginTop:"2%"}}>Edit Image Slide</h1>
                
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="caption" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Caption: </label>
                            </div>
                            <div className="col">
                                <textarea style={{marginTop:"1%", width:"70%", height: "40vh"}} id="caption" name="caption"></textarea>
                            </div>
                        </div>
                    </div>

                    

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="imageFile" style={{width:"100%", marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Current Image: </label>
                            </div>
                            <div className="col">
                                <img id="currentImage" style={{marginTop:"1%", width:"25vw", height:"50vh"}} src=''/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>New Image: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row text-center" style={{marginTop:"1%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}} onClick={handleEditSlide} type="button" className="btn btn-primary">Edit Slide</button>

                        </div>
                    </div>
                    <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}}  onClick={handleDeleteSlide} type="button" className="btn btn-danger">Delete Slide</button>
                        </div>
                    </div>

                
        </div>
        </div>
        
    )
}

// import React from "react";
// import './managementDashboard.css'
// import { useState } from "react";
// import { API_BASE_URL } from '../../../constants'
// import {addManager, getManagers} from '../../../services/management'
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// function ManagementDashboard() {
//     const [management, setManagement] = useState([]);
//     const [newImageFile, setNewImageFile] = useState(null);
//     const navigate = useNavigate();
//     useEffect(()=>{
//         getManagers().then(respond =>{
//             setManagement(respond.data.data);
//         }).catch(err=>{
//             console.error(err);
//             alert("Couldn't load managers.");
//         })
//     },[])

//     function handleAddManager(){
//         var data = new FormData();

//         data.append('name', document.getElementById('name').value);
//         data.append('email', document.getElementById('email').value);
//         data.append('role', document.getElementById('role').value);

//         data.append('image', newImageFile[0]);
 
//         addManager(data).then(response=>{
//          var managementClone = [...management];
//          managementClone.push(response.data.data);
//          setManagement(managementClone);
//          alert("Manager is added successfully");
 
//         }).catch(err=>{
//          console.log(err);
//          alert("couldn't add the Manager");
//         })
//     }

//     function onEditClick(id){
//         window.sessionStorage.setItem('manager', id);
//         navigate('/dashboard/management/edit');
//     }

//     return (
//         <>

//             <div className="contact-section">
//                 <div className="container-fluid" style={{margin:"0", padding:"0", width:"100%"}}>
//                     <div className="row justify-content-center" style={{margin:"0", padding:"0", width:"100%"}}>
//                         <div className="col-12 col-lg-8 text-center">
//                             <span className="contact-section-sub">COME JOIN WITH us</span>
//                             <h2 className="contact-section-head">MANAGING COMMITTEE</h2>
//                         </div>

//                     </div>
//                 </div>
       
//                     <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                        
//                         <div className="col-12 col-lg-12 contact-address" style={{margin:"0", padding:"0", width:"100%"}}>
//                             <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
//                                 {
//                                     management.map(manager=>{
//                                         return (
//                                             <>
//                                             <div className='col-md-3  head-address-section'>
//                                                 <div className='address-title'>
//                                                     <img className="contact-img" src={API_BASE_URL+'/images/'+manager.image.path} alt="" />
//                                                     <h6 className="contact-name">{manager.name}</h6>
//                                                     <p className="contact-position">
//                                                         <small className='address-text-muted'>
//                                                             {manager.role}
//                                                         </small>
//                                                     </p>
                                                
//                                                     <p className="contact-mail">
//                                                         <small className='address-text-muted'>{manager.email}</small>
//                                                     </p>
                                                    // <button onClick={()=>onEditClick(manager.id)} style={{ backgroundColor:"yellow", width:"100%", height:"100%"}}>Edit</button>
//                                                 </div>
                                               
//                                             </div>
// {/* 
//                                             <div className="row" >
//                                                 <div className='row' style={{marginBottom:"3%", padding:"0",}}>
//                                                     <div className="col-sm-1 " style={{width:"90%", height:"100%", padding:"0"}}>
//                                                         <button onClick={()=>onEditClick(activity.id)} style={{ backgroundColor:"yellow", width:"100%", height:"100%"}}>Edit</button>
//                                                     </div>
//                                                 </div>
//                                             </div> */}
//                                             </>
//                                         )
//                                     })
//                                 }


//                                 {/*
                                
//                                  <div className='col-md-3  head-address-section'>
//                                                 <div className='address-title'>
//                                                     <img className="contact-img" src={API_BASE_URL+'/images/'+manager.image.path} alt="" />
//                                                     <h6 className="contact-name">{manager.name}</h6>
//                                                     <p className="contact-position">
//                                                         <small className='address-text-muted'>
//                                                             {manager.role}
//                                                         </small>
//                                                     </p>
                                                
//                                                     <p className="contact-mail">
//                                                         <small className='address-text-muted'>{manager.email}</small>
//                                                     </p>
//                                                 </div>
//                                             </div>
                                                                
                               

//                                <div className='col-md-3 head-address-section'>

//                                     <div className='address-title '>
//                                         <img className="contact-img" src={contactImg2} alt="" />
//                                         <h6 className="contact-name">REKHIN SOMAN</h6>
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>
//                                                 VICE-PRESIDENT
//                                             </small>
//                                         </p>
                                       
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>example@gmail.com</small>
//                                         </p>
//                                     </div>
//                                 </div>
//                                 <div className='col-md-3  head-address-section'>
//                                     <div className='address-title'>
//                                         <img className="contact-img" src={contactImg3} alt="" />

//                                         <h6 className="contact-name">IRSHAD M U</h6>
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>
//                                                 GENERAL SECRETARY
//                                             </small>
//                                         </p>
                                        
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>example@gmail.com</small>
//                                         </p>                                    </div>
//                                 </div>
//                                 <div className='col-md-3  head-address-section'>
//                                     <div className='address-title'>
//                                         <img className="contact-img" src={contactImg4} alt="" />

//                                         <h6 className="contact-name">AJAS APPADATH</h6>
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>
//                                             TREASURER
//                                             </small>
//                                         </p>
                                      
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>example@gmail.com</small>
//                                         </p>
//                                     </div>
//                                 </div> */}
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//                  <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
//                     <div className='row text-center' style={{width:"100%", margin:"0", padding:"0", backgroundColor:"#F5F5F5"}}>
//                         <h1 style={{color: "orange", marginTop:"2%"}}>Add New Manager</h1>
//                         <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
//                             <div className="form-group">
//                                 <div className="col">
//                                     <label htmlFor="name" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Name: </label>
//                                 </div>
//                                 <div className="col">
//                                     <input style={{marginTop:"1%", width:"30%"}} type="text" id="name" name="name"></input>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
//                             <div className="form-group">
//                                 <div className="col">
//                                     <label htmlFor="email" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Email: </label>
//                                 </div>
//                                 <div className="col">
//                                     <input style={{marginTop:"1%", width:"30%"}} type="email" id="email" name="email"></input>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
//                             <div className="form-group">
//                                 <div className="col">
//                                     <label htmlFor="role" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Role: </label>
//                                 </div>
//                                 <div className="col">
//                                     <input type="text" style={{marginTop:"1%",  width:"30%"}} id="role" name="role"></input>
//                                 </div>
//                             </div>
//                         </div>
                        
//                         <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
//                             <div className="form-group">
//                                 <div className="col">
//                                     <label htmlFor="imageFile" style={{marginBottom:"1%", marginTop:"1%", width:"100%"}}>Image: </label>
//                                 </div>
//                                 <div className="col">
//                                     <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
//                             <div className="col text=center">
//                                 <button style={{marginTop:"1%", width:"25%"}} onClick={handleAddManager} type="button" className="btn btn-primary">Add Manager</button>
//                             </div>
//                         </div>

//                     </div>
//                 </div>               
         
      

            

//         </>
//     )
// }

// export default ManagementDashboard; 









import React from "react";
import './managementDashboard.css'
import { useState } from "react";
import { API_BASE_URL, IMAGES_URL } from '../../../constants'
import {addManager, getManagers} from '../../../services/management'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ManagementDashboard() {
    const [management, setManagement] = useState([]);
    const [newImageFile, setNewImageFile] = useState(null);
    const navigate = useNavigate();
    useEffect(()=>{
        getManagers().then(respond =>{
            setManagement(respond.data.data);
        }).catch(err=>{
            console.error(err);
            alert("Couldn't load managers.");
        })
    },[])

    function handleAddManager(){
        var data = new FormData();

        data.append('name', document.getElementById('name').value);
        data.append('email', document.getElementById('email').value);
        data.append('role', document.getElementById('role').value);

        data.append('image', newImageFile[0]);
 
        addManager(data).then(response=>{
         var managementClone = [...management];
         managementClone.push(response.data.data);
         setManagement(managementClone);
         alert("Manager is added successfully");
 
        }).catch(err=>{
         console.log(err);
         alert("couldn't add the Manager");
        })
    }

    function onEditClick(id){
        window.sessionStorage.setItem('manager', id);
        navigate('/dashboard/management/edit');
    }

    return (
        <>


                <div className="container-fluid" style={{margin:"0", padding:"0", width:"100%", height:"100%",}}>
                            
                <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                    <div className="col text-center">
                        <span className="contact-section-sub">COME JOIN WITH us</span>
                        <h2 className="contact-section-head">MANAGING COMMITTEE</h2>
                    </div>
                </div>

                <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                    
                    <div className="col-12 col-lg-12 contact-address" style={{margin:"0", padding:"0", width:"100%"}}>
                        <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                            {
                                management.map(manager=>{
                                    return (
                                        <>
                                        <div key={manager.id} className='col-md-3  head-address-section'>
                                            <div className='container' style={{height:"100%",position:"relative"}}>
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <img className="contact-img" src={IMAGES_URL+'/'+manager.image.path} alt="" />
                                                    </div>
                                                </div>

                                                <div className="row" style={{marginBottom:"20%"}}>
                                                    <div className="col text-center">
                                                        <h6 className="contact-name">{manager.name}</h6>
                                                        <p className="contact-position">
                                                            <small className='address-text-muted'>
                                                                {manager.role}
                                                            </small>
                                                        </p>
                                                        
                                                        <p className="contact-mail">
                                                            <small className='address-text-muted'>{manager.email}</small>
                                                        </p>

                                                        <button onClick={()=>onEditClick(manager.id)} style={{ backgroundColor:"yellow", width:"100%", }}>Edit</button>

                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>

                                        </>
                                    )
                                })
                            }

                        </div>
                    </div>

                </div>
                </div>
                 <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
                    <div className='row text-center' style={{width:"100%", margin:"0", padding:"0", backgroundColor:"#F5F5F5"}}>
                        <h1 style={{color: "orange", marginTop:"2%"}}>Add New Manager</h1>
                        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                            <div className="form-group">
                                <div className="col">
                                    <label htmlFor="name" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Name: </label>
                                </div>
                                <div className="col">
                                    <input style={{marginTop:"1%", width:"30%"}} type="text" id="name" name="name"></input>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                            <div className="form-group">
                                <div className="col">
                                    <label htmlFor="email" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Email: </label>
                                </div>
                                <div className="col">
                                    <input style={{marginTop:"1%", width:"30%"}} type="email" id="email" name="email"></input>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                            <div className="form-group">
                                <div className="col">
                                    <label htmlFor="role" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Role: </label>
                                </div>
                                <div className="col">
                                    <input type="text" style={{marginTop:"1%",  width:"30%"}} id="role" name="role"></input>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row text-center" style={{width:"100%", margin:"0", padding:"0"}}>
                            <div className="form-group">
                                <div className="col">
                                    <label htmlFor="imageFile" style={{marginBottom:"1%", marginTop:"1%", width:"100%"}}>Image: </label>
                                </div>
                                <div className="col">
                                    <input style={{marginTop:"1%", width:"auto"}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                                </div>
                            </div>
                        </div>

                        <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                            <div className="col text=center">
                                <button style={{marginTop:"1%", width:"25%"}} onClick={handleAddManager} type="button" className="btn btn-primary">Add Manager</button>
                            </div>
                        </div>

                    </div>
                </div>               
         
      

            

        </>
    )
}

export default ManagementDashboard; 

























import React from "react";
import '../ManagementDashboard/managementDashboard.css'
import { useState } from "react";
import { API_BASE_URL, IMAGES_URL } from '../../../constants'
import {addManager, getManagers} from '../../../services/management'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addImageSlide, getImageSlides } from "../../../services/imageslides";

export default function ImageSlides() {
    const [imageSlides, setImageSlides] = useState([]);
    const [newImageFile, setNewImageFile] = useState(null);
    const navigate = useNavigate();

    useEffect(()=>{
        getImageSlides().then(respond =>{
            setImageSlides(respond.data.data);
        }).catch(err=>{
            console.error(err);
            alert("Couldn't load image slides.");
        })
    },[])

    function handleAddSlide(){
        var data = new FormData();

        data.append('caption', document.getElementById('caption').value);
        data.append('image', newImageFile[0]);
 
        addImageSlide(data).then(response=>{
         var slidesClone = [...imageSlides];
         slidesClone.push(response.data.data);
         setImageSlides(slidesClone);
         alert("Image slide is added successfully");
 
        }).catch(err=>{
         console.log(err);
         alert("couldn't add the image slide");
        })
    }

    function onEditClick(id){
        window.sessionStorage.setItem('imageSlide', id);
        navigate('/dashboard/imageslides/edit');
    }

    return (
        <>

            {/* <div className="contact-section"> */}
                <div className="container-fluid" style={{margin:"0", padding:"0", width:"100%", height:"100%",}}>
                    {/* <div className="row justify-content-center" style={{margin:"0", padding:"0", width:"100%"}}>
                        <div className="col-12 col-lg-8 text-center">
                            <span className="contact-section-sub">COME JOIN WITH us</span>
                            <h2 className="contact-section-head">MANAGING COMMITTEE</h2>
                        </div>

                    </div> */}
                {/* </div> */}
                    
                    <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                        <div className="col text-center">
                            <h1>Image Slides</h1>
                        </div>
                    </div>

                    <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                        
                        <div className="col-12 col-lg-12 contact-address" style={{margin:"0", padding:"0", width:"100%"}}>
                            <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                                {
                                    imageSlides.map((slide, index)=>{
                                        return (
                                            <>
                                            <div key={index} className='col-md-3  head-address-section'>
                                                <div className='container' style={{height:"100%",position:"relative"}}>
                                                    <div className="row">
                                                        <div className="col text-center">
                                                            <img className="contact-img" src={IMAGES_URL+'/'+slide.image.path} alt="" />
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{marginBottom:"20%"}}>
                                                        <div className="col text-center">
                                                            <p style={{color:"white", wordWrap:"break-word"}}>
                                                                {slide.caption}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{position: "absolute", bottom:"0", width:"100%"}}>
                                                        <div className="col text-center">
                                                            <button onClick={()=>onEditClick(slide.id)} style={{backgroundColor:"yellow", width:"100%", height:"100%", alignItems: "bottom"}}>Edit</button>
                                                        </div>
                                                    </div>
                                                    {/* <div style={{}}>
                                                    <button onClick={()=>onEditClick(slide.id)} style={{backgroundColor:"yellow", width:"100%", height:"100%", alignItems: "bottom"}}>Edit</button>
                                                    </div> */}
                                                </div>
                                               
                                            </div>

                                            </>
                                        )
                                    })
                                }

                            </div>
                        </div>

                    </div>

                    <div className='row text-center' style={{width:"100%", margin:"0", padding:"0", backgroundColor:"#F5F5F5", height:"100%",}}>
                        <h1 style={{color: "orange", marginTop:"2%"}}>Add New Image Slide</h1>
                        
                        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                            <div className="form-group">
                                <div className="col">
                                    <label htmlFor="caption" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Caption: </label>
                                </div>
                                <div className="col">
                                    <textarea style={{marginTop:"1%", width:"70%", padding:"2%"}} type="text" id="caption" name="caption"></textarea>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                            <div className="form-group">
                                <div className="col">
                                    <label htmlFor="imageFile" style={{marginBottom:"1%", marginTop:"1%", width:"100%"}}>Image: </label>
                                </div>
                                <div className="col">
                                    <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                                </div>
                            </div>
                        </div>

                        <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                            <div className="col text=center">
                                <button style={{marginTop:"1%", width:"25%"}} onClick={handleAddSlide} type="button" className="btn btn-primary">Add Slide</button>
                            </div>
                        </div>

                    </div>


                </div>

                
                 {/* <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0", height:"100%",}}>
                    <div className='row text-center' style={{width:"100%", margin:"0", padding:"0", backgroundColor:"#F5F5F5", height:"100%"}}>
                        <h1 style={{color: "orange", marginTop:"2%"}}>Add New Image Slide</h1>
                        
                        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                            <div className="form-group">
                                <div className="col">
                                    <label htmlFor="caption" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Caption: </label>
                                </div>
                                <div className="col">
                                    <textarea style={{marginTop:"1%", width:"70%", padding:"2%"}} type="text" id="caption" name="caption"></textarea>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                            <div className="form-group">
                                <div className="col">
                                    <label htmlFor="imageFile" style={{marginBottom:"1%", marginTop:"1%", width:"100%"}}>Image: </label>
                                </div>
                                <div className="col">
                                    <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                                </div>
                            </div>
                        </div>

                        <div className="row text-center"style={{marginTop:"1%", marginBottom:"5%", width:"100%"}}>
                            <div className="col text=center">
                                <button style={{marginTop:"1%", width:"25%"}} onClick={handleAddSlide} type="button" className="btn btn-primary">Add Slide</button>
                            </div>
                        </div>

                    </div>
                </div>                 */}
         
      

            

        </>
    )
}


import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { login } from "../../services/auth";




export default function Login(){

    const navigate = useNavigate();

    function handleLogin(){
        var formData ={}

        formData.username = document.getElementById('username').value;
        formData.password = document.getElementById('password').value;

        login(formData).then(response => {
            window.localStorage.setItem('token', response.data.data.token);
            window.dispatchEvent(new Event("login"));
            navigate('/');
        }).catch(err=>{
            alert("Couldn't login");
        })

    }

    return (
        <div className="container-fluid" style={{marginTop:0, backgroundColor:"#F5F5F5", height:"100vh",}}>
        {/* <div className='row text-center' style={{width:"100%", marginRight:"0", marginLeft:"0%", }}> */}
           

                <div className="row" style={{marginBottom:"5%",}}>
                    <div className="col text-center">
                        <h1 style={{color:"orange", marginTop:"5%"}}>Login</h1>
                    </div>
                </div>
               
                <div className="row" style={{padding:"0", marginLeft:"25%", marginRight:"25%"}}>
                        <div className="col-sm-1 text-center" style={{width:"29%", marginRight:"1%", padding:"0", inlineSize:"2cm"}}>
                            <label htmlFor="username" style={{  marginBottom:"5%",}}> Username: </label>
                        </div>
                        <div className="col-sm-1 text-center" style={{width: "70%", padding:"0"}}>
                            <input  type="text"  id="username" name="username"  style={{  padding:"1%", width:"100%", marginBottom:"5%"}} />
                        </div>
                </div>

                <div className="row" style={{padding:"0", marginLeft:"25%", marginRight:"25%"}}>
                        <div className="col-sm-1 text-center" style={{width:"29%", marginRight:"1%", padding:"0", inlineSize:"2cm"}}>
                            <label htmlFor="password"  style={{ marginBottom:"5%",}}> Password: </label>
                        </div>
                        <div className="col-sm-1 text-center" style={{width:"70%", padding:"0"}}>
                            <input  type="text"  id="password" name="password"  style={{padding:"1%",  width:"100%", marginBottom:"15%",}} />
                        </div>
                </div>

                <div className="row" style={{width:"100%", padding:"0"}}>
                    <div className="col text-center">
                        <button onClick={handleLogin} type="button" className="btn btn-primary">Login</button>
                    </div>
                </div>
            {/* </div> */}
            
            
                                
    
        </div>
    )
}
import './App.css';
import Home from './Pages/Home/home';
import Navbar from './Components/Navbar/navbar';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Footer from './Components/Footer/footer';
import FootBar from './Components/FootBar/footBar';
import About from './Pages/About/about';
import Facility from './Pages/Facility/facility';
import Event from './Pages/Event/event';
import Management from './Pages/Management/management';
import Galleryy from './Pages/Gallery/gallery';
import Contact from './Pages/Contact/contact'
import WhatssApp from './Components/WhatssApp/whatssApp';
import ImageCategories from './Pages/Gallery/ImageCategories';
import Gallery from './Pages/Gallery';
import ViewImages from './Pages/Gallery/ViewImages';
import GalleryDashboard from './Pages/Dashboard/GalleryDashboard';
import ImageCategoriesDashboard from './Pages/Dashboard/GalleryDashboard/ImageCategoriesDashboard';
import ViewImagesDashboard from './Pages/Dashboard/GalleryDashboard/ViewImagesDashboard';
import AddImageCategory from './Pages/Dashboard/GalleryDashboard/AddImageCategory';
import ActivitiesDashoard from './Pages/Dashboard/activities/ActivitiesDashboard';
import EventsDashboard from './Pages/Dashboard/events/EventsDashboard';
import EditActivity from './Pages/Dashboard/activities/EditActivity';
import EditEvent from './Pages/Dashboard/events/EditEvent';
import OtherFields from './Pages/Dashboard/otherfields/otherfields';
import ManagementDashboard from './Pages/Dashboard/ManagementDashboard/ManagementDashboard';
import EditManager from './Pages/Dashboard/ManagementDashboard/EditManager';
import EditNewsBar from './Pages/Dashboard/NewsBar/EditNewsBar';
import ImageSlides from './Pages/Dashboard/ImageSlides/ImageSlides';
import EditImageSlide from './Pages/Dashboard/ImageSlides/EditImageSlide';
import Login from './Pages/Login/Login';
import router from './'
import axios from 'axios';
import { API_BASE_URL } from './constants';
import { useEffect, useState } from 'react';
import Contacts from './Pages/Dashboard/Contacts/Contacts';


{/*
function remove() {
  if (location === "contact.js")
  {
    ('.searchbar').hide();
   
  }
}*/}
let token = window.localStorage.getItem('token');
window.addEventListener('login', () => {
  // console.log("App login token: " + window.localStorage.getItem('token'));
  token = window.localStorage.getItem('token');//we need to update the token onlogin.
})



axios.interceptors.request.use(request => {

  const isApiUrl = request.url.startsWith(API_BASE_URL+'/api');
  console.log("request interceptor, isApiUrl: ", isApiUrl);
  if (token && isApiUrl) {
      // request.headers.common.Authorization = `Bearer ${token}`;
      request.headers.Authorization = `Bearer ${token}`;      
      console.log("request headers: ", request.headers);
  }

  return request;

})


axios.interceptors.response.use(function (response) {
  return response;
},
 function (error) {
  console.log("interceptor!")
  if(error.response.status == 401){
    console.log("redirecting to login")
    window.localStorage.removeItem('token');
    window.location.href = '/login';
  }
  return Promise.reject(error);
});
  

function App() {

 const [tok, setTok] = useState(window.localStorage.getItem('token'));

 useEffect(()=>{
  window.addEventListener('login', () => {
    console.log("App login token: " + window.localStorage.getItem('token'));
    setTok(window.localStorage.getItem('token'));
  })
 },[])
 
 
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          {
            (Boolean(tok))?
            <>
            <Route path='/' element={<Home />}></Route>
            {/* <Route path='/about' element={<About />}></Route> */}
             {/* <Route path='/facility' element={<Facility />}></Route>  */}
            {/* <Route path='/gallery' element={<Gallery />}></Route>
            <Route path='/news&events' element={<Event />}></Route>
            <Route path='/management' element={<Management />}></Route>
            <Route path='/contact' element={<Contact />}></Route>
            <Route path={'/gallery/categories'} element={<ImageCategories />} ></Route>
            <Route path={`/gallery/images`} element={<ViewImages />} /> */}
            {/* <Route path={`/dashboard/gallery`} element={<GalleryDashboard />} /> */}

            <Route path={`/dashboard/gallerycategories`} element={<ImageCategoriesDashboard />} />
            <Route path={`/dashboard/galleryimages`} element={<ViewImagesDashboard />} />
            <Route path={'/dashboard/gallerycategories/add'} element={<AddImageCategory />} />
            <Route path={'/dashboard/activities'} element={<ActivitiesDashoard />} />
            <Route path={'/dashboard/activities/edit'} element={<EditActivity />} />
            <Route path={'/dashboard/events/edit'} element={<EditEvent />} />
            <Route path={'/dashboard/events'} element={<EventsDashboard />} />
            <Route path='/dashboard/otherfields' element={<OtherFields />} />
            <Route path='/dashboard/management' element={<ManagementDashboard />} />
            <Route path='/dashboard/management/edit' element={<EditManager />} />
            <Route path='/dashboard/newsbar' element={<EditNewsBar />} />
            <Route path='/dashboard/imageslides' element={<ImageSlides />} />
            <Route path='/dashboard/imageslides/edit' element={<EditImageSlide />} />
            <Route path='/dashboard/contacts' element={<Contacts />} />

            </>
            :
            <Route path='*' element={<Navigate to={'/login'} />} />
          }
        







         
        </Routes>
        {/* <WhatssApp /> */}
        {/* <Footer /> */}
        <FootBar />
      </Router>



    </>
  );
}

export default App;

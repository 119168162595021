import { useState } from "react";
import { useEffect } from "react";
import { API_BASE_URL, IMAGES_URL } from "../../../constants";
import { deleteActivity, editActivity, getActivity } from "../../../services/activities";
import { useNavigate } from "react-router";
import { deleteManager, editManager, getManager } from "../../../services/management";






export default function EditManager(){
    const managerId = window.sessionStorage.getItem('manager');

    const [currentActivity, setCurrentActivity] = useState(null);
    const [newImageFile, setNewImageFile] = useState('');
    const navigate = useNavigate();

    useEffect(()=>{
        if(!Boolean(managerId)){
            navigate('/dashboard/management');
        }

        getManager(managerId).then(response=>{
            document.getElementById('name').value = response.data.data.name;
            document.getElementById('email').value = response.data.data.email;
            document.getElementById('role').value = response.data.data.role;
            document.getElementById('currentImage').src = IMAGES_URL+'/'+response.data.data.image.path;
        })
    },[])

    function handleEditManager(){
        var formData = new FormData();
        formData.append('name', document.getElementById('name').value);
        formData.append('email', document.getElementById('email').value);
        formData.append('role', document.getElementById('role').value);

        formData.append('image', newImageFile[0]);
        formData.append('_method', 'put');

        editManager(managerId, formData).then(response =>{
            if(response.data.data.image !== undefined) document.getElementById('currentImage').src = IMAGES_URL+'/'+response.data.data.image.path;
            alert('Manager is updated successfully');
        }).catch(err=>{
            console.log(err);
            alert("Couldn't update the manager");
        })
    }

    function handleDeleteManager(){
        if(window.confirm("Are you sure you want to delete this manager?")){
            deleteManager(managerId).then(response =>{
                alert(response.data.message);
                navigate('/dashboard/management');
            }).catch(err=>{
                console.log(err);
                alert("Couldn't delete the manager.");
            })
        }
       
    }

    return (
        <div className="container-fluid" style={{marginTop:0, padding:0, backgroundColor:"#F5F5F5"}}>
            <div className='row text-center' style={{width:"100%", marginRight:"0", marginLeft:"0%", }}>
                <h1 style={{color:"orange", marginTop:"2%"}}>Edit Manager</h1>
                
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="name" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Name: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%", width:"30%"}} type="text" id="name" name="name"></input>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="email" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Email: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%", width:"30%"}} type="email" id="email" name="email"></input>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="role" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Role: </label>
                            </div>
                            <div className="col">
                                <textarea style={{marginTop:"1%",  width:"80%",  padding:"1%"}} type="text" id="role" name="role"></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="imageFile" style={{width:"100%", marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Current Image: </label>
                            </div>
                            <div className="col">
                                <img id="currentImage" style={{marginTop:"1%", width:"25vw", height:"50vh"}} src=''/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label htmlFor="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>New Image: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row text-center" style={{marginTop:"1%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}} onClick={handleEditManager} type="button" className="btn btn-primary">Edit Manager</button>

                        </div>
                    </div>
                    <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}}  onClick={handleDeleteManager} type="button" className="btn btn-danger">Delete Manager</button>
                        </div>
                    </div>

                
        </div>
        </div>
        
    )
}
import { useState } from "react";
import { useEffect } from "react";
import { API_BASE_URL, IMAGES_URL } from "../../../constants";
import {deleteEvent, editEvent, getEvent } from "../../../services/events";
import { useNavigate } from "react-router";






export default function EditEvent(){
    const eventId = window.sessionStorage.getItem('event');

    const [currentEvent, setCurrentEvent] = useState(null);
    const [newImageFile, setNewImageFile] = useState('');
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    const navigate = useNavigate();

    useEffect(()=>{
        if(!Boolean(eventId)){
            navigate('/dashboard/activities');
        }
        
        getEvent(eventId).then(response=>{
            document.getElementById('title').value = response.data.data.title;
            document.getElementById('startDay').value = response.data.data.startDay;
            document.getElementById('startMonth').value = response.data.data.startMonth;
            document.getElementById('startYear').value = response.data.data.startYear;
            document.getElementById('endDay').value = response.data.data.endDay;
            document.getElementById('endMonth').value = response.data.data.endMonth;
            document.getElementById('endYear').value = response.data.data.endYear;




            document.getElementById('currentImage').src =IMAGES_URL+'/'+response.data.data.image.path;
        })
    },[])

    function handleEditEvent(){
        // var formData = new FormData();
        // formData.append('title', document.getElementById('title').value);
        // formData.append('startDate', document.getElementById('startDate').value);
        // formData.append('endDate', document.getElementById('endDate').value);
        // formData.append('image', newImageFile[0]);
        // formData.append('_method', 'put');

        var data = new FormData();

        
        var sd = document.getElementById('startDay').value;
        console.log('sd: ', sd);
        if(sd!= '' && sd < 1 || sd>31) {alert("Wrong value for the start day"); return;}

        var sy = document.getElementById('startYear').value;
        if(sy != '' && sy < 2022) {alert("Wrong value for the start Year"); return;}

        var ed = document.getElementById('endDay').value;
        if(ed != '' && ed < 1 || ed>31) {alert("Wrong value for the end day"); return;}

        var ey = document.getElementById('endYear').value;
        if(ey != '' && ey < 2022) {alert("Wrong value for the end Year"); return;}

        if(newImageFile == null) {alert("Event Image can't be empty"); return;}

       data.append('title', document.getElementById('title').value);    
        
       data.append('startDay', sd); 
       data.append('startMonth', document.getElementById('startMonth').value);
       data.append('startYear', sy);

       data.append('endDay', ed);
       data.append('endMonth', document.getElementById('endMonth').value);
       data.append('endYear', ey);

       data.append('image', newImageFile[0]);
       data.append('_method', 'put');

        editEvent(eventId, data).then(response =>{
            if(response.data.data.image !== undefined) document.getElementById('currentImage').src = IMAGES_URL+'/'+response.data.data.image.path;
            alert('Event is updated successfully');
        }).catch(err=>{
            console.log(err);
            alert("Couldn't update the event");
        })
    }

    function handleDeleteEvent(){
        if(window.confirm("Are you sure you want to delete this event?")){
            deleteEvent(eventId).then(response =>{
                alert(response.data.message);
                navigate('/dashboard/events');
            }).catch(err=>{
                console.log(err);
                alert("Couldn't delete the event.");
            })
        }
       
    }

    return (
        <div className="container-fluid" style={{marginTop:0, padding:0, backgroundColor:"#F5F5F5"}}>
            <div className='row text-center' style={{width:"100%", marginRight:"0", marginLeft:"0%", }}>
                <h1 style={{color:"orange", marginTop:"2%"}}>Edit Event</h1>
                
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label for="title" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Title: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%", width:"30%"}} type="text" id="title" name="title"></input>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Starts On: </label>
                            </div>
                            <div className="col text-center">
                                <div className="row text-center" style={{paddingLeft:"30%", paddingRight:"30%"}}>
                                    <div className="col">
                                    <input type="number" placeholder='Day' style={{marginRight:"1%"}} id="startDay" name="startDay" min={1} max={31} />
                                    </div>
                                    <div className="col">
                                    <select type="number" id="startMonth" name="startMonth">
                                    <option id=''></option>
                                        {
                                        months.map((m,i)=>{
                                            return (
                                                <option key={i+1} value={i+1}>{m}</option>
                                            )
                                        })
                                        }
                                    </select>
                                    </div>

                                    <div className="col">
                                    <input type="number" placeholder='Year' style={{marginLeft: "1%"}} id="startYear" name="startYear"  min={2022}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Ends On: </label>
                            </div>
                            <div className="col text-center">
                                <div className="row text-center" style={{paddingLeft:"30%", paddingRight:"30%"}}>
                                    <div className="col">
                                    <input type="number" placeholder='Day' style={{marginRight:"1%"}} id="endDay" name="endDay" min={1} max={31} />
                                    </div>
                                    <div className="col">
                                    <select type="number" id="endMonth" name="endMonth">
                                        <option id=''></option>
                                        {
                                        months.map((m,i)=>{
                                            return (
                                                <option key={i+1} value={i+1}>{m}</option>
                                            )
                                        })
                                        }
                                    </select>
                                    </div>

                                    <div className="col">
                                    <input type="number" placeholder='Year' style={{marginLeft: "1%"}} id="endYear" name="endYear"  min={2022}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label for="imageFile" style={{width:"100%", marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Current Image: </label>
                            </div>
                            <div className="col">
                                <img id="currentImage" style={{marginTop:"1%", width:"50%"}} src=''/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label for="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>New Image: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row text-center" style={{marginTop:"1%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}} onClick={handleEditEvent} type="button" className="btn btn-primary">Edit Event</button>

                        </div>
                    </div>
                    <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}}  onClick={handleDeleteEvent} type="button" className="btn btn-danger">Delete Event</button>
                        </div>
                    </div>

                
        </div>
        </div>
        
    )
}
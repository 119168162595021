import axios from "axios";
import { API_BASE_URL } from "../constants";

const token = window.localStorage.getItem('token');

export const login = async (formData) =>{
    return await axios.post(API_BASE_URL + '/api/login', formData, );
}

export const logout = async () => {
    const response = await axios.get(API_BASE_URL + '/api/logout');
    return response;
    
}

//{headers: { Authorization: `Bearer ${token}` }}
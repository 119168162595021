import { useState, useEffect } from "react";
import { getContacts } from "../../../services/contacts";





export default function Contacts(){

    const [conatcts, setContacts] = useState([]);

    useEffect(()=>{
        getContacts().then(response =>{
            setContacts(response.data.data);
        }).catch(err=>{
            alert("Couldn't load contacts");
            console.error(err);
        })
    }, [])

    return (
        <div className="container-fluid" style={{marginTop:0, padding:0, backgroundColor:"#F5F5F5", height:"100vh"}}>
            <div className="row text-center">
                <div className="col text-center">
                    <h1 style={{color:"orange"}}>Contacts</h1>
                </div>
            </div>
            
            <div className="row">
                <div className="col">
                    <table class="table" style={{margin:"3%"}}>
                        <thead style={{width:"100%",}}>
                            <tr>
                            <th style={{width:"10%"}} scope="col">#</th>
                            <th style={{width:"15%"}} scope="col">Name</th>
                            <th style={{width:"10%"}} scope="col">Email</th>
                            <th style={{width:"10%"}} scope="col">Phone</th>
                            <th style={{width:"55%"}} scope="col">Message</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                conatcts.map(contact=>{
                                    return(
                                        <tr>
                                        <th scope="row">{contact.id}</th>
                                        <td>{contact.name}</td>
                                        <td>{contact.email}</td>
                                        <td>{contact.phone}</td>
                                        <td>{contact.message}</td>
                                        </tr>
                                    )
                                })
                            }
                          



                            {/* <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                            </tr>
                            <tr>
                            <th scope="row">3</th>
                            <td>Larry</td>
                            <td>the Bird</td>
                            <td>@twitter</td>
                            </tr> */}
                            
                        </tbody>
                    </table>
                </div>
            </div>
                    
        </div>
    )
}
// import '../../../Components/HomeEvent/homeEvent.css';
// import React, { useState } from "react";
// import { API_BASE_URL } from '../../../constants';
// import { addEvent, getEvents } from '../../../services/events';
// import { useNavigate } from "react-router-dom";
// import { useEffect } from 'react';


// export default function EventsDashboard() {

//     const [events, setEvents] = useState([]);
//     const [activeEvent, setActiveEvent] = useState(null);
//     const [newImageFile, setNewImageFile] = useState(null);
//     const navigate = useNavigate();

//     useEffect(()=>{
//         getEvents().then(response=>{
//             setEvents(response.data.data);
//             setActiveEvent(0);
//         }).catch(err=>{
//             console.log(err);
//             alert("Couldn't load activities.");
//         })
//     },[])

//     function handleAddEvent(){
//         var data = new FormData();

//        data.append('title', document.getElementById('title').value);
//        data.append('date', document.getElementById('date').value);
//        data.append('image', newImageFile[0]);

//        addEvent(data).then(response=>{
//         var eventsClone = [...events];
//         eventsClone.push(response.data.data);
//         setEvents(eventsClone);
//         alert("Event is added successfully");

//        }).catch(err=>{
//         console.log(err);
//         alert("couldn't add the event");
//        })

//     }

//     function onEditClick(id){
//         console.log("moving to edit "+id);
//         window.sessionStorage.setItem('event', id);
//         navigate('/dashboard/events/edit')
//     }

//     return (
//         <>
        
//             <div className="event">
//                     <div className='event-bg-img' style={{backgroundSize:""}}>
//                         <div >
//                             <div className="event-section">
//                                 <div className="container">
                                    

//                                     <div className="row mt-5">
//                                     <h5 style={{marginBottom:"2%", marginTop:"2%"}} className="event-title">Upcoming Events</h5>
//                                         <div className=" col-sm-12 col-md-5  col-lg-5 col-12">
//                                             <div className="work-inside-new mx-2 mt-3">
//                                                 <img className='event-img' src={(events[activeEvent]!== undefined)? API_BASE_URL +'/images/'+ events[activeEvent].image.path: ""} alt={(events[activeEvent])? events[activeEvent].name : "No Events"} />
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-12  col-md-5 col-lg-5 col-12 d-flex flex-row" >

//                                             <div className="d-flex flex-column event-items text-white">
//                                                 {
//                                                     events.map((e, index)=>{
//                                                         return(
//                                                             <span className="icons-span" style={{height:"100%"}} onClick={() => {setActiveEvent(index)}} >
//                                                                 <img className='event-logo-img' src={API_BASE_URL+'/images/'+ e.image.path} alt="" />
//                                                             </span>
//                                                         )
//                                                     })                                                       
//                                                 }         

//                                             </div>

//                                             <div className="table-content d-flex flex-column">
//                                                 {
//                                                     events.map((e,index)=>{
//                                                         return(
//                                                             <div style={{marginBottom:"3%"}}>
//                                                                 <span className={(activeEvent === index) ? "event-options-items" : "selected-event-options-items  "} style={{height:"100%"}} onClick={() => {setActiveEvent(index)}}>
//                                                                     <p className="table-date">{e.date}<strong></strong><br /> <span className='table-description' >{e.title}</span> </p> {" "}
//                                                                 </span>
//                                                                 <button style={{width:"30%", backgroundColor:"yellow", borderRadius:"50%"}} onClick={()=>onEditClick(e.id)}>Edit</button>
//                                                             </div>
                                                            
//                                                         )
//                                                     })
//                                                 }
                                               
//                                             </div>

//                                         </div>
//                                         {/* <div className="col-sm-12  col-md-2 col-lg-2 ">
                                           
//                                         </div> */}
                                        
                                        
//                                         <div className='row text-center' style={{width:"100%", marginTop:"5%", marginRight:"0", marginLeft:"0%", backgroundColor:"#F5F5F5"}}>
//                                             <h1 style={{color: "orange", marginTop:"2%"}}>Add New Event</h1>
//                                             <div className="row">
//                                                 <div className="form-group">
//                                                     <div className="col">
//                                                         <label for="title" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Title: </label>
//                                                     </div>
//                                                     <div className="col">
//                                                         <input style={{marginTop:"1%", width:"30%"}} type="text" id="title" name="title"></input>
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                             <div className="row">
//                                                 <div className="form-group">
//                                                     <div className="col">
//                                                         <label for="date" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Date: </label>
//                                                     </div>
//                                                     <div className="col">
//                                                         <input type="date" style={{marginTop:"1%",  width:"50%"}} id="date" name="date"></input>
//                                                     </div>
//                                                 </div>
//                                             </div>
                                            
//                                             <div className="row">
//                                                 <div className="form-group">
//                                                     <div className="col">
//                                                         <label for="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Image: </label>
//                                                     </div>
//                                                     <div className="col">
//                                                         <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                             <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
//                                                 <div className="col text=center">
//                                                     <button style={{marginTop:"1%", width:"25%"}} onClick={handleAddEvent} type="button" className="btn btn-primary">Add Event</button>
//                                                 </div>
//                                             </div>

//                                     </div>


//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }
















import '../../../Components/HomeEvent/homeEvent.css';
import React, { useState } from "react";
import { API_BASE_URL, IMAGES_URL } from '../../../constants';
import { addEvent, getEvents } from '../../../services/events';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';


export default function EventsDashboard() {
    const screen500 = window.visualViewport.width <= 500;
    const [events, setEvents] = useState([]);
    const [activeEvent, setActiveEvent] = useState(null);
    const [newImageFile, setNewImageFile] = useState(null);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const navigate = useNavigate();

    useEffect(()=>{
        getEvents().then(response=>{
            setEvents(response.data.data);
            setActiveEvent(0);
        }).catch(err=>{
            console.log(err);
            alert("Couldn't load events.");
        })
    },[])

    function handleAddEvent(){
        var data = new FormData();

        
        var sd = document.getElementById('startDay').value;
        console.log('sd: ', sd);
        if(sd!= '' && sd < 1 || sd>31) {alert("Wrong value for the start day"); return;}

        var sy = document.getElementById('startYear').value;
        if(sy != '' && sy < 2022) {alert("Wrong value for the start Year"); return;}

        var ed = document.getElementById('endDay').value;
        if(ed != '' && ed < 1 || ed>31) {alert("Wrong value for the end day"); return;}

        var ey = document.getElementById('endYear').value;
        if(ey != '' && ey < 2022) {alert("Wrong value for the end Year"); return;}

        if(newImageFile == null) {alert("Event Image can't be empty"); return;}

       data.append('title', document.getElementById('title').value);    
        
    //    var sm = document.getElementById('startMonth').value;
    //    if(sd && (sm || sy)) sd+='-';
    //    if(sm && sy) sm+='-';
    //    data.append('start_date', sd+sm+sy);

    //    var em = document.getElementById('endMonth').value;
    //    if(ed && (em || ey)) ed+='-';
    //    if(em && ey) em+='-';
    //    data.append('end_date', ed+em+ey);

       data.append('startDay', sd); 
       data.append('startMonth', document.getElementById('startMonth').value);
       data.append('startYear', sy);

       data.append('endDay', ed);
       data.append('endMonth', document.getElementById('endMonth').value);
       data.append('endYear', ey);

       data.append('image', newImageFile[0]);

       addEvent(data).then(response=>{
        var eventsClone = [...events];
        eventsClone.push(response.data.data);
        setEvents(eventsClone);
        alert("Event is added successfully");

       }).catch(err=>{
        console.log(err);
        alert("couldn't add the event");
       })

    }

    function onEditClick(id){
        console.log("moving to edit "+id);
        window.sessionStorage.setItem('event', id);
        navigate('/dashboard/events/edit')
    }

    return (
        <>
        
            <div className="event">
                    <div className='event-bg-img' style={{backgroundSize:""}}>
                        <div >
                            <div className="event-section">
                                <div className="container">
                                    
                                    { 
                                        (screen500)?
                                        <>
                                        <div className="row">
                                            <div className="col text-center">
                                                <h5 style={{marginBottom:"4%", marginTop:"2%"}} className="event-title">Upcoming Events</h5>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginBottom:"7%"}}>
                                            <div className="col" >
                                                <img className='event-img' src={(events[activeEvent]!== undefined)? IMAGES_URL+'/'+ events[activeEvent].image.path: ""} alt={(events[activeEvent])? events[activeEvent].name : "No Events"} />
                                            </div>
                                        </div>

                                        <div className="row text-center">
                                            <div className="col text-center">	
                                                {
                                                    events.map((e, index)=>{
                                                        return(
                                                            <>
                                                            <div key={index} className="row my-auto text-center">
                                                                <div className="col-sm-1 text-center" style={{width:"auto", marginRight: "3%", marginBottom:"2%"}}>
                                                                    <img onClick={() => {setActiveEvent(index)}} className='event-logo-img' src={IMAGES_URL+'/'+ e.image.path} alt="" />
                                                                </div>
                                                                <div className="col-sm-1" style={{width:"auto", }}>
                                                                    <span className={(activeEvent === index) ? "event-options-items" : "selected-event-options-items  "} style={{height:"100%"}} onClick={() => {setActiveEvent(index)}}>
                                                                        <p className="table-date">
                                                                        {e.start_date}<strong></strong><br /> 
                                                                        {
                                                                            (e.end_date)?
                                                                                <>-{e.end_date}<strong></strong><br /> </>
                                                                            :
                                                                                <></> 
                                                                        }
                                                                        <span className='table-description' >{e.title}</span>  </p> {" "}
                                                                    </span>

                                                                </div>
                                                            </div>

                                                            <div className="row" style={{marginBottom:"4%", }}>
                                                                <div className="col" style={{width:"auto", textAlign:"left"}}>
                                                                    <button style={{width:"30%",backgroundColor:"yellow", borderRadius:"50%"}} onClick={()=>onEditClick(e.id)}>Edit</button>
                                                                </div>
                                                            </div>
                                                        </>

                                                        
                                                        )
                                                    })                                                       
                                                }         

                                            </div>

                                                    
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="row">
                                        <div className="col text-center">
                                            <h5 style={{marginBottom:"4%", marginTop:"2%"}} className="event-title">Upcoming Events</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-1" style={{width:"40%", marginRight:"3%"}}>
                                            <img className='event-img' src={(events[activeEvent]!== undefined)? IMAGES_URL+'/'+ events[activeEvent].image.path: ""} alt={(events[activeEvent])? events[activeEvent].name : "No Events"} />
                                        </div>

                                        <div className="col-sm-1" style={{width:"57%"}}>	
                                            {
                                                events.map((e, index)=>{
                                                    return(
                                                        <>
                                                        <div key={index} className="row my-auto">
                                                            <div className="col-sm-1" style={{width:"auto", marginRight: "3%", marginBottom:"2%"}}>
                                                                <img onClick={() => {setActiveEvent(index)}} className='event-logo-img' src={IMAGES_URL+'/'+ e.image.path} alt="" />
                                                            </div>
                                                            <div className="col-sm-1" style={{width:"auto", }}>
                                                                <span className={(activeEvent === index) ? "event-options-items" : "selected-event-options-items  "} style={{height:"100%"}} onClick={() => {setActiveEvent(index)}}>
                                                                    <p className="table-date">
                                                                        {e.start_date}<strong></strong><br /> 
                                                                        {
                                                                            (e.end_date)?
                                                                                <>-{e.end_date}<strong></strong><br /> </>
                                                                            :
                                                                                <></> 
                                                                        }
                                                                        <span className='table-description' >{e.title}</span> </p> {" "}
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <div className="row" style={{marginBottom:"4%", }}>
                                                            <div className="col" style={{width:"auto"}}>
                                                                <button style={{width:"30%",backgroundColor:"yellow", borderRadius:"50%"}} onClick={()=>onEditClick(e.id)}>Edit</button>
                                                            </div>
                                                        </div>
                                                        </>
                                                       
                                                    )
                                                })                                                       
                                            }         

                                        </div>

                                                
                                    </div>
                                    </>
                                }
                                        
                                        <div className='row text-center' style={{width:"100%", marginTop:"5%", marginRight:"0", marginLeft:"0%", backgroundColor:"#F5F5F5"}}>
                                            <h1 style={{color: "orange", marginTop:"2%"}}>Add New Event</h1>
                                            <div className="row">
                                                <div className="form-group">
                                                    <div className="col">
                                                        <label for="title" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Title: </label>
                                                    </div>
                                                    <div className="col">
                                                        <input style={{marginTop:"1%", width:"30%"}} type="text" id="title" name="title"></input>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group">
                                                    <div className="col">
                                                        <label style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Starts On: </label>
                                                    </div>
                                                    <div className="col text-center">
                                                        <div className="row text-center" style={{paddingLeft:"30%", paddingRight:"30%"}}>
                                                            <div className="col">
                                                            <input type="number" placeholder='Day' style={{marginRight:"1%"}} id="startDay" name="startDay" min={1} max={31} />
                                                            </div>
                                                            <div className="col">
                                                            <select type="number" id="startMonth" name="startMonth">
                                                            <option id=''></option>
                                                               {
                                                                months.map((m,i)=>{
                                                                    return (
                                                                        <option key={i+1} value={i+1}>{m}</option>
                                                                    )
                                                                })
                                                               }
                                                            </select>
                                                            </div>

                                                            <div className="col">
                                                            <input type="number" placeholder='Year' style={{marginLeft: "1%"}} id="startYear" name="startYear"  min={2022}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group">
                                                    <div className="col">
                                                        <label style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Ends On: </label>
                                                    </div>
                                                    <div className="col text-center">
                                                        <div className="row text-center" style={{paddingLeft:"30%", paddingRight:"30%"}}>
                                                            <div className="col">
                                                            <input type="number" placeholder='Day' style={{marginRight:"1%"}} id="endDay" name="endDay" min={1} max={31} />
                                                            </div>
                                                            <div className="col">
                                                            <select type="number" id="endMonth" name="endMonth">
                                                                <option id=''></option>
                                                               {
                                                                months.map((m,i)=>{
                                                                    return (
                                                                        <option key={i+1} value={i+1}>{m}</option>
                                                                    )
                                                                })
                                                               }
                                                            </select>
                                                            </div>

                                                            <div className="col">
                                                            <input type="number" placeholder='Year' style={{marginLeft: "1%"}} id="endYear" name="endYear"  min={2022}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="form-group">
                                                    <div className="col">
                                                        <label for="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Image: </label>
                                                    </div>
                                                    <div className="col">
                                                        <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                                                <div className="col text=center">
                                                    <button style={{marginTop:"1%", width:"25%"}} onClick={handleAddEvent} type="button" className="btn btn-primary">Add Event</button>
                                                </div>
                                            </div>

                                    </div>


                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


// import './navbar.scss'
// import './navbar.css'
// // import './navbartest.css'

// import NavSearch from '../NavSearch/navSearch';
// import { NavLink } from "react-router-dom";
// import cmpLogo from '../../Images/Logo.jpg'
// import { BsInstagram } from "react-icons/bs";
// import { FaFacebook } from "react-icons/fa";
// import { FaTwitter } from "react-icons/fa";



// export default function Navbar() {
// 	// constructor(props) {
//     //     super(props);

//     //     this.closeNavbar = this.closeNavbar.bind(this);
//     //     this.state = {
//     //         isOpen: false
//     //     };
//     // }

//     function closeNavbar() {
//         this.setState({
//             isOpen: false
//         });
//     }


// 	return (
// 		<>
// 			<div className="d-flex"> 
// 			<div className="headign-name">
			
// 			<NavSearch/>
// 			<div style={{ width: "100%", justifyContent:"center" }}>{/*className="" */}

// 							<a   href='/'>
// 								<img className='nav-comp-logo' src={cmpLogo} alt="" />
// 							</a>
						

// 						<div  className='register-link'>
// 							<span className='register-text'>Are You A Member? <a href="#" className='register-btn'>Register / </a><a href="#" className='register-btn'>Login</a></span>
// 						</div>
// 					</div>
// 					<div className='nav-icons'>
// 						<a href="https://www.facebook.com/MalayaleeSamajamAUH/">
// 							{" "}
// 							<FaFacebook className="social-nav" />
// 						</a>
// 						<a href="/">
// 							<BsInstagram className="social-nav" />
// 						</a>
// 						<a href="/">
// 							<FaTwitter className="social-nav" />
// 						</a>



// 					</div>
// 				</div>

// 			<input id="burger" type="checkbox" />

// 			<label for="burger">
// 				<span></span>
// 				<span></span>
// 				<span></span>
// 			</label>

// 			<nav >
// 				<ul className='container navbar-nav'>
// 					<li className='nav-item'>
// 						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/">
// 							HOME
// 						</NavLink>
// 					</li>
// 					<li className='nav-item'>
// 						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/about">
// 							ABOUT US
// 						</NavLink>
// 					</li>
// 					{/*
// 					 <li className='nav-item'>
// 						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/facility">
// 							OUR FACILITY
// 						</NavLink>
// 					</li> */}
// 					<li className='nav-item'>
// 						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/gallery">
// 							GALLERY
// 						</NavLink>
// 					</li>
// 					<li className='nav-item'>
// 						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/news&events">
// 							NEWS & EVENTS
// 						</NavLink>
// 					</li>

// 					<li className='nav-item'>
// 						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/management">
// 						MANAGING COMMITTEE
// 						</NavLink>
// 					</li>

// 					<li className='nav-item'>
// 						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/contact">
// 							CONTACT
// 						</NavLink>
// 					</li>
// 				</ul>
// 			</nav>
// 			</div>
// 		</>
// 	)
// }

















import './navbar.scss'
import './navbar.css'
// import './navbartest.css'

import NavSearch from '../NavSearch/navSearch';
import { NavLink } from "react-router-dom";
import cmpLogo from '../../Images/Logo.jpg'
import { BsInstagram } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { logout } from '../../services/auth';



export default function Navbar() {
	

	const screen800 = window.visualViewport.width<800 && window.visualViewport.width>425;
	const screen425 = window.visualViewport.width<425;

	const token = window.localStorage.getItem('token');
	
    function closeNavbar() {
        this.setState({
            isOpen: false
        });
    }

	

	const openNav = ()=>{
		document.getElementById("mySidenav").style.width = "100%"
		// setDisplayNav(true);
	}

	const closeNav = ()=>{
		document.getElementById("mySidenav").style.width = "0";
		// setDisplayNav(false)
	}

	function handleLogout(){
		logout().then(response=>{
			window.localStorage.removeItem('token');
			window.location.href = '/login';
			alert(response.data.message);
		}).catch(err=>{
			console.log(err);
			alert("Couldn't logout!");
		})

		console.log("logout function!");
	}
	

	return (
		
			<div className="d-flex" style={{height: "9vh"}}> {/*d-flex*/}


				



				<div className="row" style={{width: "100%", margin:"0", justifyContent: "left"}}> {/*className="headign-name" */}

					<div className="col-sm-1 my-auto" style={{ width: "5%",  margin: "1%", padding:"0"}}>
						<div id="mySidenav" class="sidenav">
							<a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>
							<a href= "/">Dashboard</a>
							<a href= "/dashboard/gallerycategories">Gallery</a>
							<a href= "/dashboard/events">Events</a>
							<a href= "/dashboard/activities">Activities</a>
							<a href="/dashboard/management">Managing Committiee</a>
							<a href= "/dashboard/imageslides">Image Slides</a>
							<a href= "/dashboard/newsbar">News Bar</a>
							<a href= "/dashboard/otherfields">Other Fields</a>
							<a href= "/dashboard/contacts">Contacts</a>



							
						</div>
			
						<span style={{fontSize: '30px', cursor:"pointer",}} onClick={openNav}>&#9776;</span>
			
					</div>









					<div className="col-sm-1 my-auto text-center" style={{width:"32%", marginRight: "1%", padding:"0"}}>
						{/* <NavSearch/> */}

				
						{/* <div class="d-flex align-items-center nav-search h-100 " >
							<div class="searchbar">
								<input class="search_input" type="text" name="" placeholder="Search..." />
								<a href="#" class="search_icon"><i class="search-icon fas fa-search"></i></a>
							</div>
						</div> */}
					

						{/* <input class="search_input" style={{width: "80%"}} type="search" name="" placeholder="Search..." />
                        <a href="#" class="search_icon"><i class="search-icon fas fa-search"></i></a> */}
					</div>
					
					<div className="col-sm-1 my-auto text-center" style={{ width: "20%", justifyContent:"center",  }}>
						<a   href='/'>
							{/* <img className='nav-comp-logo' style={{width: (screen800)? "40%" : (screen425)? "80%": "20%"}} src={cmpLogo} alt="" /> */}
							<img className='nav-comp-logo' src={cmpLogo} alt="" />

						</a>
					</div>

					<div className="col-sm-1 my-auto" style={{ width: "40%", height:"100%", justifyContent:"center", margin: "0", padding:"0", textAlign:"end" }}>	
						
						<div style={{width: (screen425 || screen800)?"60%": "40%", height: "100%" ,backgroundColor: "#424243", float:"right", padding:"0", margin:"0"}}>
							{/* <div className="col my-auto text-center" style={{ width: "20%", justifyContent:"center" }}>						 */}
							
							{ (Boolean(token))?
							<div className="row my-auto text-center" style={{width: "100%", height:"48%", padding:"0", marginRight:"0", marginLeft:"0", marginBottom:"0.5%", marginTop:"1%"}}>
								<div  className='register-link'>
									<span className='register-text'> <a onClick={handleLogout} className='register-btn'>Logout</a></span>
								</div>	
							</div>
							:<></>
							}
							
							{/* <div className="col" style={{ width: "20%", justifyContent:"center" }}>						 */}
							{/* <div className="row text-center" style={{width: "100%",  height:"50%", margin:"0", padding:"0", }}>

								<div style={{width:"100%", margin:"0", padding:"0"}}>
									<a href="/" >
										<FaTwitter  className="social-nav"/>
									</a>
									<a href="/" >
										<BsInstagram  className="social-nav"/>
									</a>
								
									<a href="https://www.facebook.com/MalayaleeSamajamAUH/" >
										<FaFacebook  className="social-nav"/>
									</a>
								
								</div>
						
							</div> */}
						</div>
					
					</div>

					
				
				

					</div>

		
			
			</div>
			
	)
}









/**
 	<input id="burger" type="checkbox" />

			<label for="burger">
				<span></span>
				<span></span>
				<span></span>
			</label>

			<nav >
				<ul className='container navbar-nav'>
					<li className='nav-item'>
						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/">
							HOME
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/about">
							ABOUT US
						</NavLink>
					</li>
					{/*
					 <li className='nav-item'>
						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/facility">
							OUR FACILITY
						</NavLink>
					</li> 
					<li className='nav-item'>
						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/gallery">
							GALLERY
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/news&events">
							NEWS & EVENTS
						</NavLink>
					</li>

					<li className='nav-item'>
						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/management">
						MANAGING COMMITTEE
						</NavLink>
					</li>

					<li className='nav-item'>
						<NavLink className="nav-link" onClick={closeNavbar} data-toggle="collapse" to="/contact">
							CONTACT
						</NavLink>
					</li>
				</ul>
			</nav>
 */
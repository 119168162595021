import axios from "axios";
import { API_BASE_URL } from "../constants";

export const getImageSlides = async()=>{
    const response = axios.get(API_BASE_URL+ '/api/imageslides');
    return response;
}

export const getImageSlide = async(imageSlideId)=>{
    const response = axios.get(API_BASE_URL+ '/api/imageslides/'+imageSlideId);
    return response;
}

export const addImageSlide = async(formData)=>{
    const response = axios.post(API_BASE_URL+ '/api/imageslides/add', formData);
    return response;
}

export const editImageSlide = async(imageSlideId, formData)=>{
    const response = axios.post(API_BASE_URL+ '/api/imageslides/'+imageSlideId+'/edit', formData);
    return response;
}

export const deleteImageSlide = async(imageSlideId)=>{
    const response = axios.delete(API_BASE_URL+ '/api/imageslides/'+imageSlideId+'/delete');
    return response;
}


import React, { useState } from 'react'
import './homeAbout.css'
import { NavLink } from "react-router-dom";
import Keralammwater from '../../Images/Keralammwater.jpg'
import Water from '../../Images/Water.jpg'
import { useEffect } from 'react';
import { Button } from 'react-scroll';
import { getField } from '../../services/general-fields';


 function HomeAbout() {
    
    // return (
    //     <>
    //         <div className="container">
               
    //               {/*<div class=" about-card mb-3">*/}
    //                 <div class="row">
                    
    //                     <div className='col-md-4'>
    //                     <img className='water' src={Water} alt="" />

    //                     </div>
                       
    //                    <div class="col-md-4">
    //                         <div class="card-body">
    //                             <div className='about-head'>
    //                             <div >
    //                 <h1 className='about-title'>
    //                    About Us
    //                 </h1>
    //             </div>
    //                                 <h1 className='about-main mb-5 mt-4'>
    //                                     Abu Dhabi Malayalee
    //                                     Samajam Since 1969
    //                                 </h1>

    //                                 <p className='about-description mb-3'>Being one of the escalating lands in terms of social welfare, quality of life, verdant, ecological diversity, education, literacy, art & cultural heritage, Kerala is renowned as the God’s own country with its beautiful land called as paradise. </p>
    //                                 <p className='about-description '>
    //                                 We, Abu Dhabi Malayalee Samajam aims to promote and revive the traditions of the great land and maintain a gathering for the Keraliates residing in Abu Dhabi, UAE since 1969 swear the importance of the Kerala community in the country. Abudhabi Malayalee Samajam has inculcated in bringing morals and entice people in promoting the vivid traditions and ethics  from time to time.We always encouraged an embracing attitude towards the people of Kerala and gave out the best possible in fulfilling the mission to zeal the rich culture and heritage along with humanitarian concerns. Food & Medicines are also supplied to the less-privileged people who are really in need by organizing various fund-raising activities to help people facing crisis during natural calamities[...]</p>
                                      
                                           
    //                 <NavLink className="about-btn-txt" to="/about">
    //                     <button type="button" class="btn about-home-btn ">
    //                         Read <span className='title-design'>more</span>
    //                     </button>
    //                     </NavLink>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div class=" col-md-4">
    //                         <img className='aboutussImg' src={Keralammwater} alt="" />
    //                     </div>


    //                 </div>
    //             {/*</div>*/}

    //         </div>
    //         <div className="row">
    //             <div className="col-lg-6 col-md-6 col-12">
    //                 <div className='bg-image'>

    //                 </div>
    //             </div>
    //             <div className="col-lg-6 col-md-6 col-12">

    //             </div>
    //         </div>
    //     </>
    // )



    const isMobile = visualViewport.width <= 900 ;

    const [aboutUs, setAboutUs] = useState('');
    useEffect(()=>{
        getField('about_us').then(response=>{
            setAboutUs(response.data.data.value.split(' ').slice(0,200).join(' '));
        }).catch(err=>{
            console.error(err);
            alert("Couldn't load about us.");
        })
        //const first2 = str.split(' ').slice(0, 2).join(' ');
    },[])
   
    return (
        <>
        {
            (isMobile)? //render for Mobile Phones
            <div className="container-fluid" style={{width:"100%", padding:"0%", margin:"0px"}}>
               
            {/* <div className ="row" style={{maxWidth:"100%"}}> */}
            
                <div  className ="row" style={{margin:"0", padding:"0"}}>
                  <img className='water' src={Water} alt="" style={{margin:"0", padding:"0"}}/>

                </div>
               
                <div  className ="row" style={{margin:"0", padding:"0"}}> {/* style={{maxWidth:"100%", width:"100", padding:"0%"}} */}
                    <div className="card-body">
                        <div className='about-head' style={{marginRight:"15%", marginLeft:"15%", padding:"0"}}>
                        
                        <div className='row text-center' style={{width:"100%", margin:"0", padding:"0"}}></div>
                            <h1 className='about-title'>
                                About Us
                            </h1>
                        <div/>
                     

                        <div className='row text-center' style={{width:"100%", margin:"0", padding:"0"}}>
                            <h1 className='about-main mb-5 mt-4'>
                                Abu Dhabi Malayalee
                                Samajam Since 1969
                            </h1>
                        </div>
                        <div className='row' style={{width:"100%", margin:"0", padding:"0"}}>
                            <div style={{wordWrap: "break-word"}}>
                                {/* <p className='about-description mb-3'>Being one of the escalating lands in terms of social welfare, quality of life, verdant, ecological diversity, education, literacy, art & cultural heritage, Kerala is renowned as the God’s own country with its beautiful land called as paradise. </p>
                                <p className='about-description'>
                                We, Abu Dhabi Malayalee Samajam aims to promote and revive the traditions of the great land and maintain a gathering for the Keraliates residing in Abu Dhabi, UAE since 1969 swear the importance of the Kerala community in the country. Abudhabi Malayalee Samajam has inculcated in bringing morals and entice people in promoting the vivid traditions and ethics  from time to time.We always encouraged an embracing attitude towards the people of Kerala and gave out the best possible in fulfilling the mission to zeal the rich culture and heritage along with humanitarian concerns. Food & Medicines are also supplied to the less-privileged people who are really in need by organizing various fund-raising activities to help people facing crisis during natural calamities[...]</p>
                                 */}

                                <p className='about-description'>{aboutUs} [...]</p>
                                
                            </div>
                        </div>
                           
                        <div className='row text-center' style={{width:"100%", margin:"0", padding:"0"}}>
                            <NavLink className="about-btn-txt" to="/about" style={{width:"100%",}}>
                                <button type="button" className="btn about-home-btn">
                                    Read <span className='title-design'>more</span>
                                </button>
                            </NavLink>
                        </div>
                            {/* <NavLink className="about-btn-txt" to="/about" style={{width:"100%",}}>
                                <button type="button" className="btn about-home-btn">
                                    Read <span className='title-design'>more</span>
                                </button>
                            </NavLink>
                               */}
                             
                                             
                         
                        </div>
                    </div>
                </div>
                <div className ="row" style={{margin:"0", padding:"0"}}> {/*style={{maxWidth:"100%", width:"100", padding:"0%"}} */}
                    <img className='aboutussImg' src={Keralammwater} alt="" style={{margin:"0", padding:"0"}} /> {/*className='aboutussImg' */}
                </div>

        {/* </div> */}
    </div>

            : //render for PCs

            <div className="container" style={{width:"100%", maxWidth:"100%", padding:"0%", margin:"0%"}}>
               
                    <div className ="row" style={{width:"100%", maxWidth:"100%", padding:"0%", margin:"0%"}}>
                    
                        <div className='col' style={{maxWidth: "20%", width:"25%", padding:"0%", margin:"0%"}}>
                          <img className='water' src={Water} alt="" />

                        </div>
                       
                        <div className="col" style={{maxWidth: "40%", }}>
                            <div className="card-body">
                                <div className='about-head'>
                                    <div className='row' style={{width:"100%"}}>
                                        <h1 className='about-title'>
                                            About Us
                                        </h1>
                                    </div>
                               
                               <div className='row' style={{width:"100%"}}>
                                    <h1 className='about-main mb-5 mt-4'>
                                        Abu Dhabi Malayalee
                                        Samajam Since 1969
                                    </h1>
                               </div>
                                 
                               <div className='row' style={{width:"100%"}}>
                                    <div style={{wordWrap: "break-word"}}>
                                       {/* <p className='about-description mb-3'>Being one of the escalating lands in terms of social welfare, quality of life, verdant, ecological diversity, education, literacy, art & cultural heritage, Kerala is renowned as the God’s own country with its beautiful land called as paradise. </p>
                                        <p className='about-description'>
                                        We, Abu Dhabi Malayalee Samajam aims to promote and revive the traditions of the great land and maintain a gathering for the Keraliates residing in Abu Dhabi, UAE since 1969 swear the importance of the Kerala community in the country. Abudhabi Malayalee Samajam has inculcated in bringing morals and entice people in promoting the vivid traditions and ethics  from time to time.We always encouraged an embracing attitude towards the people of Kerala and gave out the best possible in fulfilling the mission to zeal the rich culture and heritage along with humanitarian concerns. Food & Medicines are also supplied to the less-privileged people who are really in need by organizing various fund-raising activities to help people facing crisis during natural calamities[...]</p>
                                         */}

                                        <p className='about-description'>{aboutUs} [...]</p>
                                        
                                    </div>
                               </div>
                                  
                              
                               <div className='row text-center' style={{width:"100%", marginBottom:"5%", padding:"0"}}>
                  
                                    <NavLink className="about-btn-txt" to="/about"  style={{}}>
                                        <button type="button" className="btn about-home-btn">{/*className="btn about-home-btn*/}
                                            Read <span className='title-design'>more</span>
                                        </button>
                                    </NavLink>
                                </div>

                                </div>
                            </div>
                        </div>
                        <div className="col" style={{maxWidth: "40%", width:"35%", padding:"0%", margin:"0%"}}>
                            <img className='aboutussImg' src={Keralammwater} alt="" /> {/*className='aboutussImg' */}
                        </div>

                </div>
            </div>

        }
                
                  

      
         
        </>
    )
}


export default HomeAbout;
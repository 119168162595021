import axios from "axios";
import { API_BASE_URL } from "../constants";

export const getManagers = async ()=>{
    const response = axios.get(API_BASE_URL+'/api/managers');
    return response;
}

export const getManager = async (managerId)=>{
    const response = axios.get(API_BASE_URL+'/api/managers/'+managerId);
    return response;
}

export const addManager = async (formData)=>{
    const response = axios.post(API_BASE_URL+'/api/managers/add', formData);
    return response;
}

export const editManager = async (managerId, formData)=>{
    const response = axios.post(API_BASE_URL+'/api/managers/'+managerId+'/edit', formData);
    return response;
}

export const deleteManager = async (managerId)=>{
    const response = axios.delete(API_BASE_URL+'/api/managers/'+managerId+'/delete');
    return response;
}


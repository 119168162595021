import { useEffect, useState } from "react"
import {getField, editField} from "../../../services/general-fields";




export default function OtherFields(){

    const [selectedItem, setSelectedItem] = useState('');


    useEffect(()=>{
        if(selectedItem){
            //on select a new item
            getField(selectedItem).then(response=>{
                document.getElementById("text").value = response.data.data.value;
            }).catch(err=>{
                console.log(err);
                alert("Couldn't load "+selectedItem+" value.")
            })
        }
        else{
            //on page load
            setSelectedItem("News Bar");
            getField("News Bar").then(response=>{
                document.getElementById("text").value = response.data.data.value;
            }).catch(err=>{
                console.log(err);
                alert("Couldn't load News Bar value.")
            })
        }
     
    }, [selectedItem]);

    function handleEdit(){
        var formData = new FormData();
        formData.append('value', document.getElementById('text').value);
        formData.append('_method', "PUT");

        editField(selectedItem, formData).then(response=>{
            alert(response.data.message);
        }).catch(err=>{
            console.log(err);
            alert("Couldn't update the field.")
        });
        

    }

    function onItemChange(){
        var select = document.getElementById('itemsSelect');
        var value = select.options[select.selectedIndex].value;
        console.log("value: ", value);
        setSelectedItem(value);
    }

    return (
        <div className="container-fluid" style={{marginTop:0, padding:0, backgroundColor:"#F5F5F5",height:"100vh"}}>
        <div className='row text-center' style={{width:"100%", marginRight:"0", marginLeft:"0%", }}>
            <h1 style={{color:"orange", marginTop:"2%"}}>Other Fields</h1>
            
                <div className="row">
                    <div className="form-group">
                        <div className="col">
                            <label htmlFor="item" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Select Item To Edit: </label>
                        </div>
                        <div className="col">
                            <select style={{marginTop:"1%", width:"30%", backgroundColor: "#f7e4d7", }} id="itemsSelect" name="itemsSelect" onChange={()=>onItemChange()}>
                                <option key= "News Bar">News Bar</option>
                                <option key= "News Page">News Page</option>
                                <option key= "About Us">About Us</option>
                                <option key= "Footer Description">Footer Description</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group">
                        <div className="col">
                            <label htmlFor="text" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Text: </label>
                        </div>
                        <div className="col">
                            <textarea style={{marginTop:"1%",  width:"70%", height: "40vh", padding:"1%", backgroundColor: "#f7e4d7"}}  id="text" name="text"></textarea>
                        </div>
                    </div>
                </div>
            
                <div className="row text-center" style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                    <div className="col text=center">
                        <button style={{width:"25%"}} onClick={handleEdit} type="button" className="btn btn-primary">Edit</button>

                    </div>
                </div>
               

                
        </div>
        </div>
    )
}
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setPagesSession } from "../../Components/general-functions/helpers";
import HeroSection from "../../Components/HeroSection/heroSection";
import HomeAbout from "../../Components/HomeAbout/homeAbout";
import HomeActivities from "../../Components/HomeActivities/homeActivities";
import HomeEvent from "../../Components/HomeEvent/homeEvent";
import HomeFacility from "../../Components/HomeFacility/homeFacility";
import HomeFlash from "../../Components/HomeFlash/homeFlash";
import HomeGallery from "../../Components/HomeGallery/homeGallery";
import HomeGalleryy from "../../Components/HomeGalleryy/homeGalleryy";

export default function Home() {
    useEffect(()=>{
        setPagesSession("home");//this will remove all other pages values from the key "pages" (which is a stringified json object) of our session storage.
      },[])
      
    // return (
    //     <div >
    //     <HeroSection/>
    //     <HomeFlash />
    //     <HomeAbout />
    //     <HomeFacility />
    //     <HomeGalleryy />
    //     <HomeEvent />
    //     <HomeActivities/> 
    //     </div>
    // )

    const navigate = useNavigate();
    
    function navigateTo(to){
        navigate('/dashboard/'+to)
    }

    return (
        <div className="container-fluid" style={{width: "100%", margin:"0", backgroundColor: "#F5F5F5", height:"100vh"}}>
            <div className="row" style={{width: "100%", marginTop:"0", padding:"0"}}>
                <div className="col text-center">
                    <h1 style={{color: "Orange", marginTop:"2%"}}>Malayalee Dashboard</h1>
                </div>

            </div>

            <div className="row" style={{width: "100%", margin:"auto", padding:"0", marginTop: "5%"}}>
                <div className="row" style={{height:"5vh", marginBottom:"2%"}}>
                    <div className="col text-center">
                    <button style={{backgroundColor: "#FF6F61", width:"50%", borderRadius:"10px",  marginBottom: "1%", color:"white", height:"100%"}} onClick={()=>navigateTo("gallerycategories")}> <h3>Gallery</h3></button> 
                    </div>
                </div>

                <div className="row" style={{height:"5vh", marginBottom:"2%"}}>
                    <div className="col text-center">
                    <button style={{backgroundColor: "#6B5B95", width:"50%", borderRadius:"10px",  marginBottom: "1%", color:"white", height:"100%"}} onClick={()=>navigateTo("events")}><h3>Events</h3></button> 
                    </div>
                </div>

                <div className="row" style={{height:"5vh", marginBottom:"2%"}}>
                    <div className="col text-center">
                    <button style={{backgroundColor: "#34568B", width:"50%", borderRadius:"10px",  marginBottom: "1%", color:"white", height:"100%"}} onClick={()=>navigateTo("activities")}><h3>Activities</h3></button> 
                    </div>
                </div>

                <div className="row" style={{height:"5vh", marginBottom:"2%"}}>
                    <div className="col text-center">
                    <button style={{backgroundColor: "#92A8D1", width:"50%", borderRadius:"10px",  marginBottom: "1%", color:"white", height:"100%"}} onClick={()=>navigateTo("management")}><h3>Managing Committiee</h3></button> 
                    </div>
                </div>

                <div className="row" style={{height:"5vh", marginBottom:"2%"}}>
                    <div className="col text-center">
                    <button style={{backgroundColor: "#88B04B", width:"50%", borderRadius:"10px",  marginBottom: "1%", color:"white", height:"100%"}} onClick={()=>navigateTo("imageslides")}><h3>Image Slides</h3></button> 
                    </div>
                </div>

                <div className="row" style={{height:"5vh", marginBottom:"2%"}}>
                    <div className="col text-center">
                    <button style={{backgroundColor: "#955251", width:"50%", borderRadius:"10px",  marginBottom: "1%", color:"white", height:"100%"}} onClick={()=>navigateTo("newsbar")}><h3>News Bar</h3></button> 
                    </div>
                </div>

                <div className="row" style={{height:"5vh", marginBottom:"2%"}}>
                    <div className="col text-center">
                    <button style={{backgroundColor: "#CBA90F", width:"50%", borderRadius:"10px",  marginBottom: "1%", color:"white", height:"100%"}} onClick={()=>navigateTo("otherfields")}><h3>Other Fields</h3></button> 
                    </div>
                </div>

                <div className="row" style={{height:"5vh", marginBottom:"2%"}}>
                    <div className="col text-center">
                    <button style={{backgroundColor: "#DD4124", width:"50%", borderRadius:"10px",  marginBottom: "1%", color:"white", height:"100%"}} onClick={()=>navigateTo("contacts")}><h3>Contacts</h3></button> 
                    </div>
                </div>
            
                
               
               
            </div>

        </div>
    )
}